.walletBtns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}

.walletBtns button,
.walletBtns button:hover {
  background: transparent;
  border-radius: 10px;
  color: #42b57c;
  padding: 10px 30px;
  border: 1px solid #42b57c !important;
  box-shadow: none;
  display: flex;
}

.walletBtns button.active,
.walletBtns button.active:hover {
  background: #42b57c;
  color: white;
}

.depositeDiv {
  position: relative;
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px 20px;
  border: 1px solid #40b87a;
}

.depositeDiv::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 100%;
  background-color: #40b87a;
}

.depositeDiv-horizontalBorder {
  border-top: none !important;
}

@media (max-width: 1399.98px) {
  .depositeDiv::after {
    content: none;
    display: none;
    width: 100%;
    height: 1px;
  }

  .depositeDiv-horizontalBorder {
    border-top: 1px solid #40b87a !important;
  }
}

/* .depositeDiv .depositBorderFixer {
  border-right: 1px solid #40b87a;
} */

.depositeDiv .depositeQr {
  background: white;
  border: 1px solid #42b57c;
  border-radius: 20px;
  padding: 20px;
  width: fit-content;
  max-width: 250px;
}

.depositesAddress {
  min-width: 210px;
  width: fit-content;
  background: white;
  border: 1px solid #42b57c;
  border-radius: 10px;
  padding: 10px;
}

.depositesAddress span {
  color: white;
  background: #42b57c;
  padding: 2px 7px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
  float: right;
}

.withdrawDiv {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px;
}

.withdrawDiv label {
  font-weight: 500;
  margin-bottom: 5px;
}

.withdrawDiv select {
  height: 48px;
  border-color: #42b57c !important;
  box-shadow: none !important;
  border-radius: 10px;
}

.withdrawDiv input,
.withdrawDiv input:focus,
.withdrawDiv input:disabled,
.withdrawDiv .input-group-text,
.withdrawDiv select:disabled {
  height: 48px;
  background: white;
  border-color: #42b57c;
  box-shadow: none;
  border-radius: 10px;
}

.withdrawDiv .input-group-text button {
  background: #42b57c;
  border-color: #42b57c;
  border-radius: 10px;
  color: white;
  padding: 5px 10px;
  border: none;
  box-shadow: none;
}

.withdrawDiv .input-group input,
.withdrawDiv .input-group input:focus {
  border-right: 0;
}

.withdrawDiv .withdrawBtn,
.withdrawDiv .withdrawBtn:hover {
  display: block;
  margin: auto;
  background: #42b57c !important;
  border: none !important;
  padding: 10px;
  width: 250px;
  max-width: 100%;
}

.uploadTransactionReceipt {
  position: relative;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #40b87a;
  z-index: 10;
}

.uploadTransactionReceipt input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
}

.uploadTransactionReceipt .fileLabel {
  position: absolute;
  font-size: 14px;
  color: #838383;
  z-index: 1;
}

.uploadTransactionReceipt .fileBtn {
  position: absolute;
  right: 8px;
  width: 30%;
  height: 80%;
  background: #42b57c;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  z-index: 1;
}

.uploadTransactionReceipt .fileBtn img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* withdraw-type-dropdown */
.withdraw-type-dropdown button {
  min-width: 180px;
  height: 45px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42b57c !important;
  border-color: #42b57c !important;
}
.withdraw-type-dropdown button.dropdown-toggle::after {
  margin-left: 8px;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
  border-radius: 10px;
}

.withdraw-type-dropdown .dropdown-menu {
  width: 100%;
  font-size: 14px;
}
