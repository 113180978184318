/* layout start */
.dashboardWrapper {
  height: 100vh;
  overflow: hidden;
}

.sidebarWrapper {
  width: 260px;
  background: #f3f3f3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 999;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.dashboardLogo {
  padding: 20px 10px;
  max-width: 150px;
  margin: auto;
  text-align: center;
}

.rightSideWrapper {
  overflow-x: hidden;
  position: relative;
  background-color: white;
  width: auto;
  margin-left: 260px;
  height: 100vh;
  overflow: auto;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.headerWrapper {
  background: white;
  position: relative;
  z-index: 99;
  padding: 15px 30px;
  display: flex;
  flex-wrap: wrap;
  /* transition: all 0.5s cubic-bezier(0.4, -0.25, 0.25, 1.1); */
}

.outletWrapper {
  padding: 30px 30px 50px;
}

.slideBtn {
  display: none;
  width: 35px;
  padding: 0;
  color: black;
  font-size: 20px;
  background-color: transparent;
  border: none;
  background: transparent !important;
}

.slideCloseBtn {
  display: none;
  position: absolute;
  top: 0;
  right: -35px;
  width: 35px;
  height: 35px;
  background-color: #42b57c !important;
  color: #ffffff;
  border: none;
  font-size: 24px;
  border-radius: 0;
  padding: 0;
}

/* layout end */

/* sidebar */
.sidebarScrollDiv {
  height: calc(100vh - 85px);
  overflow-y: auto;
}

/* width */
.sidebarScrollDiv::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.sidebarScrollDiv::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: rgb(51, 51, 51, 0.2);
}

/* Handle */
.sidebarScrollDiv::-webkit-scrollbar-thumb {
  background: #42b57c;
  border-radius: 5px;
}

.sidebarMenu {
  padding: 0 0px;
  margin-bottom: 100px;
  list-style: none;
}

.sidebarMenuItem {
  margin-top: 5px;
}

.sidebarMenuItem a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 15px 25px;
  transition: all 0.3s;
  font-size: 18px;
  line-height: 1;
}

.sidebarMenuItem a.active,
.sidebarMenuItem a:hover,
.sidebarMenuItem.activeMenu > a {
  background-color: #3dabf422 !important;
  text-decoration: none;
  font-weight: 500;
  border-right: 8px solid #42b57c;
}

.sidebarMenuItem a span {
  color: #42b57c;
  font-size: 20px;
  margin-right: 15px;
  transition: all 0.5s;
  /* text-shadow: 1px 2px 5px rgb(0 0 0 / 15%); */
}

.sidebarMenuTitle {
  font-size: 0.6875rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #b5b5b5;
  margin: 25px 0 8px 25px;
}

.logoutBtn {
  padding: 25px;
}

.logoutBtn button,
.logoutBtn button:hover {
  display: block;
  background: #42b57c;
  border: none;
  box-shadow: none;
  width: 100%;
  border-radius: 10px;
  padding: 12px;
}

/* sidebar end */

/* pagination start */
.adminPagination {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.adminPagination a {
  display: block;
  width: 43px;
  height: 43px;
  line-height: 43px;
  text-align: center;
  border: 1px solid #42b57c;
  margin: 10px 5px;
  color: #42b57c !important;
  text-decoration: none !important;
  border-radius: 3px;
}

.adminPagination .paginationActive a {
  color: white !important;
  background-color: #42b57c;
}

.adminPagination .paginationLink {
  font-size: 1.4rem;
}

/* pagination end */

.headerWrapper p span {
  font-weight: 600;
  color: black;
}

.dashUserName {
  border: none;
}

.dashUserIcon img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid #42b57c;
}

.outletWrapper .refreshBtn {
  background: transparent !important;
  padding: 0;
  border: none;
  box-shadow: none;
  color: black;
  display: block;
  margin-left: auto;
}

.dashDataDiv {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px 10px 20px;
  text-align: center;
}

.dashDataDiv p {
  color: #838383;
  margin-bottom: 10px;
}

.dashLinks,
.accountOptions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media (max-width: 991px) {
  .dashLinks button {
    flex-basis: 40%;
    width: 100%;
  }
}

.dashLinks button,
.dashLinks button:hover {
  background: #42b57c;
  border-radius: 8px;
  color: white;
  padding: 10px;
  width: 160px;
  text-align: center;
  border: none;
  box-shadow: none;
}

.accountConnect {
  text-align: center;
  max-width: 700px;
  border: 1px solid #42b57c;
  border-radius: 20px;
}

.accountConnect .connectBtn {
  background: #42b57c;
  border-radius: 8px;
  color: white;
  padding: 15px 30px;
  border: none;
  box-shadow: none;
}

.accountConnect p {
  color: #838383;
}

.accountOptions span {
  background: transparent;
  border-radius: 10px;
  color: black;
  padding: 15px 30px;
  border: 1px solid #42b57c;
  box-shadow: 0 4px 7px #42b57ccc;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 50px;
}

.accountOptions span img {
  width: 100%;
  max-width: 100px;
  object-fit: contain;
  height: 75px;
}

.dashboardChart {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px 20px;
}

.dashboardChart canvas {
  width: 100%;
  max-height: 400px;
}

.tradeTableBtns button.btn,
.tradeTableBtns button.btn:hover {
  background: white;
  border: 1px solid #42b57c;
  border-radius: 10px;
  color: #42b57c;
  box-shadow: none;
  width: 200px;
  padding: 10px;
}

.tradeTableBtns button.btn.active,
.tradeTableBtns button.btn.active:hover {
  background: #42b57c;
  color: white;
}
