.authDiv {
  min-height: 90vh;
  background: url('/public/images/user/authBg.png') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.authDiv .authLogo {
  width: 200px;
  margin: auto;
  display: block;
}

.authTitle {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.loginDiv {
  width: 550px;
  max-width: 100%;
  margin: 30px auto;
  /* border: 2px solid #42B57C; */
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px;
}

.loginDiv label {
  font-weight: 500;
  margin-bottom: 5px;
}

.loginDiv input:not([type="checkbox"]),
.loginDiv input:not([type="checkbox"]):focus,
.loginDiv select,
.loginDiv select:focus,
.loginDiv .input-group-text {
  height: 48px;
  background: white;
  border-color: #42B57C;
  box-shadow: none;
  border-radius: 10px;
}

.loginDiv .input-group input,
.loginDiv .input-group input:focus {
  border-right: 0;
}

.loginDiv .loginBtn,
.loginDiv .loginBtn:hover {
  display: block;
  margin: auto;
  background: #42B57C !important;
  border: none !important;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.loginDiv .resendOtp,
.loginDiv .resendOtp:hover {
  background: transparent;
  color: #42B57C;
  border: none;
  font-size: 14px;
  padding: 0;
}

@media screen and (min-width:1600px) {

  .authDiv .bannerTxt,
  .authDiv .authImg {
    padding-right: 100px !important;
  }
}

@media screen and (max-width:991px) {

  .authImg {
    display: none;
  }
}

@media screen and (max-width:767px) {}