.teamBtns button,
.teamBtns button:hover {
  width: 240px;
  background: transparent;
  border-radius: 10px;
  color: #42B57C;
  padding: 10px;
  border: 1px solid #42B57C !important;
  box-shadow: none;
}

.teamBtns button.active,
.teamBtns button.active:hover {
  background: #42B57C;
  color: white;
}

.referralDiv {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 25px;
}

.referQr {
  width: 170px;
  padding: 15px;
  background: white;
  border: 1px solid #42B57C;
  border-radius: 10px;
}

.referShare img {
  width: 34px;
  cursor: pointer;
}

.backBtn.btn,
.backBtn.btn:hover {
  background: #42B57C;
  border: none;
  box-shadow: none;
  padding: 6px 20px;
}

.rewardDiv {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 30px 20px;
  text-align: center;
  height: 100%;
}

.rewardDiv h3 {
  font-weight: 600;
}

.rewardDiv.teamDiv {
  border: 1px solid #42B57C;
  padding: 30px 20px 20px;
}