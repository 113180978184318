.headerLogo{
  width: 120px;
  height: 120px;
}
.footerLogo {
  width: 150px;
}

.btn.themeBtn,
.btn.themeBtn:hover {
  background: #42B57C;
  color: white;
  padding: 8px 15px;
  border-radius: 10px;
  min-width: 120px;
  width: fit-content;
  text-align: center;
  border: 1px solid #42B57C !important;
}

.btn.themeBtn.headerBtn {
  background: #f3f3f3;
  color: black;
  border: none !important;
}

.btn.themeBtn.active {
  background: white;
  color: #42B57C;
}

.footerSec {
  background: #F5FBFE;
  padding: 150px 0 20px;
}

.footerSocials a {
  color: #42B57C;
  font-size: 25px;
}

.footerHead {
  color: #42B57C;
}

.footerSec ul li {
  list-style: circle;
}

.footerSec ul li::marker {
  color: #42B57C;
}

.footerContact .contactIcon {
  color: #42B57C;
  font-size: 20px;
  min-width: 20px;
  line-height: 1;
}

.newsletterSec .row {
  max-width: 1100px;
  background: url('/public/images/user/featureBg.png') no-repeat center center, #42B57C;
  background-size: cover;
  color: white;
  border-radius: 20px;
  padding: 50px 40px;
  margin: 40px auto -100px;
  position: relative;
}

.newsletterSec h2 {
  font-size: 42px;
  font-weight: 700;
}

.newsletterInput {
  position: relative;
}

.newsletterInput input {
  background: white;
  border: none !important;
  box-shadow: none !important;
  padding: 20px;
}

.newsletterInput .btn {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: #42B57C !important;
  border: none !important;
  color: white;
  font-size: 30px;
  line-height: 1;
}

/* home */

.bannerSec {
  padding: 150px 0;
  background: url('/public/images/user/bannerBg.png') no-repeat center center;
  background-size: cover;
}

.bannerTxt h2 {
  font-size: 60px;
  font-weight: 700;
}

.bannerTxt h2 span {
  color: #42B57C;
}

.bannerTxt p {
  font-size: 20px;
  color: #838383;
}

.mainTitle {
  font-size: 48px;
  font-weight: 700;
  text-align: center;
}

.featureSec,
.workingSec,
.affiliateSec,
.pricingSec {
  padding-top: 100px;
}

.featureSlider {
  background: url('/public/images/user/featureBg.png') no-repeat center center, #42B57C;
  background-size: cover;
  padding: 80px 0;
}

.owl-carousel.featureCarousel .owl-stage {
  display: flex;
}

.featureDiv {
  background: white;
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  height: 100%;
}

.owl-carousel.featureCarousel .featureDiv img {
  width: auto;
  height: 68px;
  margin: auto;
}

.featureDiv h4,
.workingDiv h4 {
  font-weight: 600;
}

.workingRow {
  background: url('/public/images/user/workingLine.png') no-repeat top center;
  background-size: 100% 100%;
  padding-bottom: 15px;
}

.workingRow>div:first-child .workingDiv {
  margin-top: 50px;
}

.workingRow>div:nth-child(2n+2) .workingDiv {
  margin-top: 100px;
}

.workingDiv {
  max-width: 310px;
  margin: auto;
  padding: 40px;
}

.workingDiv div {
  min-height: 240px;
  text-align: center;
  background: white;
  padding: 35px;
  border-radius: 20px;
  box-shadow: 0 6px 10px #DEE3E6;
  position: relative;
}

.workingDiv .stepNum {
  width: 64px;
  height: 64px;
  line-height: 64px;
  background: white;
  border: 2px solid #42B57C;
  border-radius: 10px;
  font-size: 32px;
  font-weight: 600;
  color: #42B57C;
  text-align: center;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

.workingDiv div::before {
  content: '';
  height: calc(50% + 12px);
  width: calc(100% + 24px);
  background: #42B57C;
  border-radius: 20px 20px 0 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.workingDiv div::after {
  content: '';
  height: calc(50% + 24px);
  width: calc(100% + 48px);
  background: white;
  border: 1px solid #42B57C;
  box-shadow: 0 6px 10px #DEE3E6;
  border-radius: 0 0 20px 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}

.affiliateBg {
  background: url('/public/images/user/affiliateBg.png') no-repeat center center, #42B57C;
  /* background-size: 75%; */
  text-align: center;
  color: white;
}

.affiliateSec ul {
  width: fit-content;
  margin: auto;
  text-align: left;
  text-transform: capitalize;
}

.affiliateSec .btn.themeBtn {
  color: black;
  font-weight: 600;
}

.pricingSec .container {
  background: url('/public/images/user/pricingBg.png') no-repeat center center;
  background-size: contain;
  position: relative;
  z-index: 1;
  padding: 30px 0 100px;
}

.pricingDiv {
  width: 335px;
  max-width: 90%;
  margin: auto;
  text-align: center;
  background: url('/public/images/user/pricingTxtBg.png') no-repeat center center, white;
  background-size: 100% 100%;
  padding: 0 35px 30px;
  position: relative;
}

.pricingDiv h3 {
  background: #42B57C;
  padding: 18px;
  border-radius: 0 0 20px 20px;
  font-size: 32px;
  color: white;
  font-weight: 700;
}

.pricingDiv h2 {
  font-size: 62px;
  color: #42B57C;
  font-weight: 700;
  line-height: 1;
}

.pricingDiv p {
  font-size: 20px;
  color: #838383;
}

.pricingDiv .btn.themeBtn {
  font-size: 20px;
}

@media screen and (min-width:1600px) {
  .userWrapper .container {
    max-width: 1550px;
  }
}

@media screen and (max-width:1399px) {
  .bannerTxt h2 {
    font-size: 55px;
  }

  .workingDiv div {
    padding: 35px 20px;
  }

  .workingDiv {
    padding: 40px 30px;
  }
}

@media screen and (max-width:1199px) {
  .bannerSec {
    padding: 120px 0;
  }

  .bannerTxt h2 {
    font-size: 46px;
  }

  .bannerTxt p {
    font-size: 18px;
  }

  .workingRow {
    background: none;
  }

  .workingDiv {
    padding: 40px 20px;
  }

  .workingDiv h4 {
    font-size: 22px;
  }

  .workingDiv div {
    min-height: 220px;
    padding: 25px 10px;
  }

  .workingDiv div::after {
    height: calc(50% + 20px);
    width: calc(100% + 40px);
  }

  .workingDiv div::before {
    height: calc(50% + 10px);
    width: calc(100% + 20px);
  }

  .newsletterSec h2 {
    font-size: 35px;
  }
}

@media screen and (max-width:991px) {
  .headerLogo {
    width: 170px;
  }

  .bannerSec {
    padding: 50px 0;
  }

  .mainTitle {
    font-size: 42px;
  }

  .workingRow>div:first-child .workingDiv,
  .workingRow>div:nth-child(2n+2) .workingDiv {
    margin-top: 0;
  }
}

@media screen and (max-width:767px) {
  .headerLogo {
    width: 135px;
  }
}

@media screen and (max-width:575px) {
  .bannerSec {
    background: none;
  }

  .bannerTxt h2 {
    font-size: 42px;
  }

  .mainTitle {
    font-size: 32px;
  }

  .newsletterSec .row {
    padding: 40px 20px;
  }

  .newsletterSec h2 {
    font-size: 28px;
  }
}