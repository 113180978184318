@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --blue: #3ce0d0;
  --orange: #ff9559;
  --purple: #755099;
  --bg-gray: #1f1e31;
}

body {
  font-family: "Poppins", sans-serif;
  color: black;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: white;
  transition: all 0.3s ease-in-out;
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: unset;
}

p {
  margin-bottom: 0;
}

img,
svg {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

ul {
  list-style-type: disc;
}

/* Chrome, Safari, Edge, Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="checkbox"] {
  border-color: #42b57c !important;
  box-shadow: none !important;
}

input[type="checkbox"]:checked {
  background-color: #42b57c;
}

/* confirmModal */
.confirmModal .modal-content,
.alertModal .modal-content {
  border-radius: 20px;
  padding: 10px;
}

.confirmModal .modal-content .modal-header {
  padding: 5px 10px 10px;
}

.confirmModal .modal-content .modal-footer,
.alertModal .modal-content .modal-footer {
  border-top: none;
  padding-top: 0;
}

.confirmModal .modal-footer button {
  width: 100px;
  border-radius: 10px;
}

.confirmModal .modal-footer button.modalSubmit {
  background: #42b57c;
  border: none;
}

/* alertModal */
.alertModal {
  text-align: center;
  color: #838383;
}

.alertModal .modal-content {
  background: url("/public/images/modalBg.png") no-repeat center bottom, white;
}

.alertModal .modal-content .modal-header {
  padding-bottom: 0;
  border-bottom: 0;
}

.alertModal .modal-body h3 {
  color: #42b57c;
}

.alertModal .modal-footer button,
.alertModal .modal-footer button:hover {
  margin: 10px auto 30px;
  background: #42b57c;
  border: none;
  border-radius: 10px;
  padding: 12px;
  width: 200px;
}

.alertModal .purchasePlan h2 {
  color: #42b57c;
  font-size: 42px;
}

.alertModal input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
