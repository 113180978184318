.planBtns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: start;
  gap: 10px;
}

.planBtns button,
.planBtns button:hover,
.planBtns button:disabled {
  background: #42B57C !important;
  border: 2px solid #42B57C !important;
  box-shadow: none;
  width: 100%;
  padding: 10px;
  opacity: 1;
}

.planBtns button.infoBtn,
.planBtns button.infoBtn:hover {
  background: white;
  color: #42B57C;
}

.fxAccountForm {
  background: #f3f3f3;
  max-width: 700px;
  margin: auto;
  border-radius: 20px;
  padding: 20px;
}

.fxAccountForm .fxAccInput {
  display: flex;
  align-items: start;
  gap: 10px;
}

.fxAccInput label {
  width: 150px;
}

.fxAccInput div {
  flex: 1;
}

.fxAccInput input,
.fxAccInput input:focus,
.fxAccInput select,
.fxAccInput select:focus {
  border: none;
  box-shadow: none;
}

.fxAccountSubmit.btn,
.fxAccountSubmit.btn:hover,
.fxPendingDiv .showFormBtn,
.fxPendingDiv .showFormBtn:hover {
  width: 200px;
  display: block;
  /* margin: auto; */
  background: #42B57C;
  border-radius: 10px;
  padding: 12px;
  font-size: 20px;
  border: none;
  box-shadow: none;
}

.fxPendingDiv .showFormBtn,
.fxPendingDiv .showFormBtn:hover {
  padding: 10px;
}

.forexTable thead th {
  background: #42B57C;
  padding: 12px;
  color: white;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
}

.forexTable th:first-child {
  border-radius: 20px 0 0 0;
}

.forexTable th:last-child {
  border-radius: 0 20px 0 0;
}

.forexTable tbody td {
  background: #f3f3f3;
  padding: 12px;
  text-align: center;
  vertical-align: middle;
}

.forexTable tbody tr:last-child {
  border-bottom-color: transparent;
}

.forexTable tbody tr:last-child td:first-child {
  border-radius: 0 0 0 20px;
}

.forexTable tbody tr:last-child td:last-child {
  border-radius: 0 0 20px;
}