@media (max-width: 991px) {
  .sidebarWrapper {
    left: -290px;
  }

  .sidebarWrapper.sidebarToggle {
    left: 0px;
  }

  .rightSideWrapper {
    margin-left: 0;
  }

  .slideBtn,
  .sidebarToggle .slideCloseBtn {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .walletBtns {
    gap: 10px;
  }

  .walletBtns button,
  .walletBtns button:hover {
    padding: 10px;
  }
}

@media screen and (max-width: 575px) {
  .fxAccountForm .fxAccInput {
    display: block;
  }

  .depositeDiv {
    padding: 30px 0;
  }

  .withdrawDiv {
    padding: 30px 20px;
  }

  .depositesAddress {
    font-size: 14px;
    padding: 10px;
  }

  .teamBtns button,
  .teamBtns button:hover {
    width: auto;
  }
}

@media screen and (max-width: 425px) {
  .depositesAddress {
    font-size: 10px;
    padding: 10px 5px;
  }
}