.securityAccordion .accordion-item {
  border: none;
  background: #f3f3f3;
  border-radius: 10px !important;
}

.securityAccordion .accordion-header .accordion-button {
  background: transparent !important;
  box-shadow: none;
  border: none;
  border-radius: 10px !important;
  gap: 10px;
}

.securityAccordion .accordion-button .accordionIcon {
  color: #42B57C;
  font-size: 27px;
}

.securityAccordion .accordion-button h5 {
  font-size: 18px;
  font-weight: 600;
}

.securityAccordion .accordion-button p {
  font-size: 14px;
  color: #838383;
}

.securityAccordion .accordion-body {
  position: relative;
}

.securityAccordion .accordion-body::before {
  content: '';
  width: 100%;
  height: 1px;
  background: linear-gradient(90deg, transparent, #42B57C, transparent);
  position: absolute;
  top: 0;
  left: 0;
}

.passChangeForm label {
  font-weight: 500;
  margin-bottom: 5px;
}

.passChangeForm input:not([type="checkbox"]),
.passChangeForm input:not([type="checkbox"]):focus,
.passChangeForm select,
.passChangeForm select:focus,
.passChangeForm .input-group-text {
  height: 48px;
  background: white;
  border-color: transparent;
  box-shadow: none;
  border-radius: 10px;
}

.passChangeForm .input-group input,
.passChangeForm .input-group input:focus {
  border-right: 0;
}

.passChangeForm .input-group-text button {
  background: #42B57C;
  color: white;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
}

.passChangeForm .submitBtn,
.passChangeForm .submitBtn:hover {
  display: block;
  margin: auto;
  background: #42B57C !important;
  border: none !important;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.passChangeStep {
  min-width: 36px;
  width: 36px;
  height: 36px;
  background: #838383;
  border-radius: 20px;
  color: white;
  text-align: center;
  font-size: 22px;
}

.passChangeStep.active {
  background: #42B57C;
}